
@font-face {
  font-family: "PlazaStd";
  font-weight: bold;
  src:url(./fonts/plaza-std.otf) format("opentype");
}

@font-face {
  font-family: 'Joliet';
  src: url('fonts/Joliet-Regular.woff') format('woff');
}

.App {
  text-align: center;
  font-family: "open-sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.restaurant-appendix-text {
  font-family: "casablanca-urw", sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: smaller;
  font-size: smaller;
  color: #deba54;
  position: relative;
  bottom: -5px;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.logo {
    max-width: 90%;
}

.logo-navbar {
    height: 40pt;
}

.content {
    font-size: larger;
}

.navBar {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.logo-pin {
    height: 5rem;
    width: 6rem;
    background-color: #e8e4db;
    border-radius: 10px;
    padding: 3px;
}

.pin {
    font-size: 30px;
    margin-left: -15px;
    margin-top: -30px;
    position: absolute;
}

.pdf-box {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    border-radius: 5px;
    width: calc(100% - 10%);
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
}

.pdf-box > * {
    box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.6);
}

html, body {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow-x: hidden;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}

.carousel-image {
    min-width: 100%;
    height: calc(100vh - 60px);
    background-image: url("https://backend.stelldichein.at/uploads/220420_Katharina_Schiffl_8767b_1_790d70ec56.jpeg?updated_at=2022-06-30T20:37:18.152Z");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    filter: saturate(1.25);
}

.floatingBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 50%;
    text-align: left;
}

.floatingBox1 {
    position: relative;
    max-width: 700px;
    background-color: rgba(232, 228, 219, 0.8) !important;
    box-shadow: 0 0 48px 32px rgba(0, 0, 0, 0.6) !important;
    padding: 40px;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    backdrop-filter: blur(2px);
}

.floatingBox2 {
    max-width: 700px;
    background-color: rgba(232, 228, 219, 0.8) !important;
    box-shadow: 0 0 48px 32px rgba(0, 0, 0, 0.6) !important;
    padding: 40px;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    backdrop-filter: blur(2px);
    justify-content: space-around ;
}

h1 {
    font-size: 1.75rem;
}

h2 {
    font-size: 1.25rem;
}

.defaultContainer {
    min-width: 100%;
    height: calc(100vh - 60px);
    background-image: url("https://image.kurier.at/images/cfs_landscape_1864w_1049h/1453944/46-56684183.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.menu > .defaultContainer {
    background-image: url("https://backend.stelldichein.at/uploads/220414_Katharina_Schiffl_8812_min_ea1997541a.jpg?updated_at=2022-06-25T14:45:33.839Z");
    height: calc(100vh - 60px);
}

.legals > .carousel-image {
    background-image: url("https://backend.stelldichein.at/uploads/Diesner_Stelldichein29_06_22_08_dfdc76b311.jpg?updated_at=2022-06-30T19:55:11.806Z");
}

.about-us > .carousel-image {
    background-image: url("https://backend.stelldichein.at/uploads/5d609a7b_654f_4d88_b8ec_8b3034f11435_a06c14b103.JPG?updated_at=2022-06-27T21:07:25.793Z");
}

.lounge > .carousel-image {
    background-image: url("https://backend.stelldichein.at/uploads/Diesner_Stelldichein29_06_22_101_f78d512fe4.jpg?updated_at=2022-06-30T18:24:04.036Z");
}

.events > .carousel-image {
    background-image: url("https://backend.stelldichein.at/uploads/Diesner_Stelldichein29_06_22_101_f78d512fe4.jpg?updated_at=2022-06-30T18:24:04.036Z");
}

.menu-header {
    padding-top: 16px;
}

.logoBig {
    margin-left: 0%;
}

.logoBigContainer {
  max-width: 40%;
}

.leftDivStelldichein {
    width: 50%;
    margin-right: 5%;
}

.rightDivStelldichein {
    width: 60%;
}

h1 {
    font-weight: bold;
}

.floatingBox-legals {
    height: 100% !important;
}

.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0, 0);
}

.dropdown-menu {
    margin-bottom: 10px;
}

.carretDownLink {
    display: none;
}

html {
    scroll-behavior: smooth;
}

.carretDown a {
    all: unset !important;
}

.btn-dark {
    background-color: #335929;
    border: none;
}

.btn-dark:hover {
    background-color: rgb(51, 89, 41);
    border: none;
}

.btn-dark:disabled {
    background-color: rgb(51, 89, 41);
    opacity: 0.65;
    border: none;
}

.opening-times-line {
    display: flex;
    justify-content: space-between;
}

.floatingBox h1 {
  font-family: casablanca-urw, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 2rem;
  color: #335929;
}

.bg-light {
  background-color: #e8e4db !important;
}

.opening-times {
  width: 85%;
}

.navbar-nav {
  margin-left: 50px;
}

.dropdown-menu {
  background-color: #e8e4db;
}

.leafRightDivStelldichein {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 20%;
  z-index: 100000;
  rotate: 70deg;
  /*transform: scale(-1, 1);*/  
}

.nav-link:hover {
  color: #335929 !important;
  text-decoration: underline;
  text-underline-offset: 8px;
}

#basic-nav-dropdown {
  height: 45px !important;
}

.navBarBlaetter {
  filter: saturate(1.75);
}

.leafRightDivStelldichein {
  filter: saturate(1.5);
}

@media only screen and (max-width: 600px) {
    .leftDivStelldichein {
        width: 75%;
    }
    .rightDivStelldichein {
        width: 100%;
    } 
    .floatingBox1 {
        background-color: rgba(232, 228, 219, 0.80) !important;
        box-shadow: 0 0 48px 32px rgba(0, 0, 0, 0.2) !important;
        min-height: 40vh;
        flex-direction: column-reverse;
        backdrop-filter: blur(2px);
    }
    .floatingBox2 {
        background-color: rgba(232, 228, 219, 0.80) !important;
        box-shadow: 0 0 48px 32px rgba(0, 0, 0, 0.2) !important;
        min-height: 60vh;
        flex-direction: column-reverse;
    }
    .home .carousel-image {
        flex-direction: column-reverse;
        justify-content: space-around;
        height: 200vh;
        background-image: none;
        filter: saturate(1.25);
    }
    .box2 {
        background-image: url("https://backend.stelldichein.at/uploads/220420_Katharina_Schiffl_8767b_1_790d70ec56.jpeg?updated_at=2022-06-30T20:37:18.152Z");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        flex-flow: column-reverse;
    }
    .box1 {
        background-image: url("https://backend.stelldichein.at/uploads/5d609a7b_654f_4d88_b8ec_8b3034f11435_a06c14b103.JPG?updated_at=2022-06-27T21:07:25.793Z");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .carretDown {
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #a07d14;
    }
    .carretDownLink {
        display: block;
    }
    h1 {
        font-size: 1.75rem !important;
    }
    h2 {
        font-size: 1.25rem;
    }
    .opening-times {
      width: 100%;
    }
    .leafRightDivStelldichein {
      position: absolute;
      right: 0;
      top: 0;
      width: 30%;
      z-index: 100000;
      rotate: 220deg;
      transform: scale(-1, 1);
      filter: saturate(1.25);
    }
    .logoBigContainer {
      max-width: 100%;
    }
}

@media only screen and (max-width: 992px) {
  .navBarBlaetter {
    display: none;
  }
}